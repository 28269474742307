$(function () {

    $('.tab-item').click(function () {
        let $tabId = $(this).data('carousel-id');
        let $tab = $('.tab-item');
        let $content = $('.tab-content');

        $(this).addClass('active');
        $(this).attr('data-origin-active', true);
        $tab.not(this).removeClass('active');
        $tab.not(this).removeAttr('data-origin-active');

        $content.each(function () {
            let $contentId = $(this).data('carousel-id');

            if($tabId === $contentId) {
                $(this).addClass('active');
                $content.not(this).removeClass('active');
            }
        });
    });

    $('.specialization .item').click(function () {
        let $item = $('.specialization .item');

        $(this).toggleClass('open');
        // $item.not(this).removeClass('open');
    });

    /* Side scroll */

    $('.scroll-text, .scroll-anim').click(function () {
        $("html, body").animate({
            scrollTop: $('#tab-slider').offset().top - 100
        }, 1500);
    });


    /* Scroll to top */

    $('.top-icon').click(function () {
        $("html, body").animate({
            scrollTop: $('#top').offset().top
        }, 1500);
    });



    $( ".tab-content-wrapper .tab-content" ).each(function( index ) {
        let $tabSlider = $(this).find('.tab-slider');
        $tabSlider.addClass('loaded tab-slider-' + index);

        $($tabSlider).slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            rows: 0,
            arrows: false,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                        nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                    }
                },
                {
                    breakpoint: 855,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: true,
                        prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                        nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: true,
                        prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                        nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                    }
                },
                {
                    breakpoint: 1590,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        arrows: true,
                        prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                        nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                    }
                },
                {
                    breakpoint: 1980,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        arrows: true,
                        prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                        nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                    }
                },
                {
                    breakpoint: 2370,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                        arrows: true,
                        prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                        nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                    }
                }
            ]
        });

    });


    $('.blog-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        rows: 0,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                    nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                    nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                }
            },
            {
                breakpoint: 1750,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                    nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                }
            },
            {
                breakpoint: 2300,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: '<div class="arrow-right"><i class="icon icon-arrow"></i></div>',
                    nextArrow: '<div class="arrow-left"><i class="icon icon-arrow"></i></div>'
                }
            }
        ]
    });


    let $pathname = window.location.pathname;
    let $slug = $('.specialization .item');

    $($slug).each(function( index ) {
        let $this = $(this);
        let $thisSlug = $(this).data('slug');
        if($thisSlug === $pathname) {
            $("html, body").animate({
                scrollTop: $($this).offset().top - 150
            }, 1500);
        }
    });


    if($(window).width() > 1200) {
        let scene = document.getElementById('scene');
        if (scene) {
            let parallaxInstance = new Parallax(scene);
        }
    }

    $('.tab-item').on('mouseenter', function () {
        const $tab = $(this);
        const $parent = $tab.parent();
        const $line = $tab.siblings('hr');

        const tabClientRect = $tab.get(0).getBoundingClientRect();
        const parentClientRect = $parent.get(0).getBoundingClientRect();

        const width = $tab.outerWidth();
        const left = tabClientRect.left - parentClientRect.left;
        // const top = tabClientRect.top - parentClientRect.top + tabClientRect.height;

        if(!$tab.hasClass('active')) {
            $parent.find('.tab-item.active').removeClass('active').attr('data-origin-active', true);
            $line.css({width: width, left: left});
        }

    }).on('mouseleave', function () {
        const $tab = $(this);
        const $parent = $tab.parent();
        const $line = $tab.siblings('hr');

        if(!$tab.hasClass('active')) {
            $parent.find('[data-origin-active]').addClass('active').removeAttr('data-origin-active');
            $line.css('width', '');
        }
    });


});

