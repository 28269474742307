$(function(){

    // Mobil menü nyitás/csukás
    $('.mobile-menu').on('click', function (e) {
        $('.navbar').toggleClass('open');
        $('.overlay').toggleClass('open');
        $('.navbar-right').toggleClass('open');
        $(this).toggleClass('open');
        $('body').toggleClass('open');
        $('html').toggleClass('open');
    });

    $('html').click(function(event) {
        if ($(event.target).closest('.navbar-right, .mobile-menu').length === 0) {
            $('.navbar').removeClass('open');
            $('.overlay').removeClass('open');
            $('.mobile-menu').removeClass('open');
            $('.navbar-right').removeClass('open');
            $('body').removeClass('open');
            $('html').removeClass('open');
        }
    });

    // Menü váltás scrollra
    $(document).scroll(function () {
        let $nav = $(".navbar");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
    });

});