new CookieG({
    "palette": {
        "popup": {
            "background": "#080808"
        },
        "button": {
            "background": "transparent",
            "text": "#fff",
            "border": "#fff"
        }
    },
    "content": {
        "message": window.serverVariables.cookieMessage,
        "link": window.serverVariables.cookieLinkLabel,
        "dismiss": window.serverVariables.cookieDismiss,
        "href": window.serverVariables.cookieLink
    },
    "type": "information",
    "position": "bottom-right",
});
