$(function () {
    $(document).delegate("#contact-form", "submit", function(e) {
        e.preventDefault();

        let data = {};
        const $form = $(this);

        let contactThemes = window.serverVariables.contactThemes;

        const request = function () {
            let form = $form;
            let url = form.attr('action');

            $.each($form.serializeArray(), function () {
                if (this.value) {
                    data[this.name] = this.value;
                }
            });

            // if ('g-recaptcha-response' in data) {
            //     data['captcha'] = data['g-recaptcha-response'];
            //     delete data['g-recaptcha-response'];
            // }

            $.ajax({
                type: "POST",
                url: url,
                data: {
                    form: data,
                    action: 'contact',
                    contactThemes: contactThemes
                },
                success: function(response)
                {

                    if ('formHtml' in response) {
                        let newHtmlString = response.formHtml;
                        let newHtml = $.parseHTML(newHtmlString);
                        form.replaceWith(newHtml);

                        new window.RecaptchaEvents();
                    }

                    if ('successModalHtml' in response) {
                        window.RecaptchaEvents.api.reset(form.find('[data-recaptcha-toggle]').get(0));
                        form[0].reset();
                        $("form :input").not($('select')).each(function(){
                            this.value = null;
                        });

                        $('form select option:selected').each(function (index, element) {
                            $(this).removeAttr('selected');
                        });

                        $('option[value="choose"]').attr('selected', 'selected');

                        $('.errors').hide();



                        const $modal = $(response.successModalHtml);
                        $(document.body).append($modal);

                        setTimeout(function () {
                            $modal.fadeOut(600);
                        }, 3000);

                    }
                },

                error: function (data) {
                    {



                    }
                }
            });
        };

        const onComplete = function() {

        };

        window.RecaptchaEvents.api.execute($form.find('[data-recaptcha-toggle]').get(0)).then(function () {
            request();
        }).catch(function () {
            console.log('recap error');
        })
    });


});

new window.RecaptchaEvents();